export default [
  {
    path: 'mission',
    name: 'mission',
    meta: {
      title: 'Mission'
    },
    component: () => import('./list/MissionListPage.vue')
  },
  {
    path: 'mission/:uuid',
    name: 'mission.detail',
    meta: {
      title: 'Mission'
    },
    component: () => import('./detail/MissionDetailPage.vue')
  }
]
