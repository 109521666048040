export default [
  {
    path: 'redemption',
    name: 'redemption',
    meta: {
      title: 'Point Redemption',
      subject: 'redemption'
    },
    component: () => import('./RedemptionPage.vue')
  },
  {
    path: 'redemption/:uuid',
    name: 'redemption.detail',
    meta: {
      title: 'Point Redemption'
    },
    component: () => import('./detail/RedemptionDetailPage.vue')
  }
]
