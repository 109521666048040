export default [
  {
    path: 'news',
    name: 'news',
    meta: {
      title: 'News & Events'
    },
    component: () => import('./list/NewsListPage.vue')
  },
  {
    path: 'news/:uuid',
    name: 'news.detail',
    meta: {
      title: 'News & Events'
    },
    component: () => import('./detail/NewsDetailPage.vue')
  }
]
