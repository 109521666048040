export default [
  {
    path: 'outlet/:uuid',
    name: 'outlet.detail',
    meta: {
      title: 'Explore'
    },
    component: () => import('./detail/OutletDetailPage.vue')
  }
]
