export default [
  {
    path: 'faq',
    name: 'faq',
    meta: {
      title: 'Frequently Asked Questions'
    },
    component: () => import('./list/FAQListPage.vue')
  },
  {
    path: 'faq/:uuid',
    name: 'faq.detail',
    meta: {
      title: 'Frequently Asked Questions'
    },
    component: () => import('./detail/FAQDetailPage.vue')
  }
]
