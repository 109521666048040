export default [
  {
    path: 'merchant',
    name: 'merchant',
    meta: {
      title: 'Merchant'
    },
    component: () => import('./list/MerchantListPage.vue')
  },
  {
    path: 'merchant/:uuid',
    name: 'merchant.detail',
    meta: {
      title: 'Merchant'
    },
    component: () => import('./detail/MerchantDetailPage.vue')
  }
]
