<template>
  <div class="flex min-h-screen">
    <RouterView />
  </div>
</template>

<script setup lang="ts">
import { useFavicon } from '@vueuse/core'

const { selectedProject } = useHelper()

const favicon = computed(() => {
  return selectedProject === '707' ? '707-ico.png' : 'atmos-ico.png'
})

useFavicon(favicon.value, {
  baseUrl: '/',
  rel: 'icon'
})
</script>

<style scoped></style>
