export default [
  {
    path: 'my-voucher',
    component: () => import('./list/MyVoucherListPage.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: 'my-voucher'
        }
      },
      {
        path: 'internal',
        name: 'my-voucher',
        meta: {
          title: 'My Voucher',
          subject: 'my-voucher'
        },
        component: () => import('./tabs/InternalVoucher.vue')
      },
      {
        path: 'merchant',
        name: 'my-voucher.merchant',
        meta: {
          title: 'My Voucher',
          subject: 'my-voucher'
        },
        component: () => import('./tabs/MerchantVoucher.vue')
      }
    ]
  },
  {
    path: 'my-voucher/used',
    name: 'my-voucher.used',
    meta: {
      title: 'Used Voucher',
      subject: 'my-voucher'
    },
    component: () => import('./used/UsedVoucherPage.vue')
  },
  {
    path: 'my-voucher/used/:uuid',
    name: 'my-voucher.used.detail',
    meta: {
      title: 'Used Voucher',
      subject: 'my-voucher'
    },
    component: () => import('./used-detail/UsedVoucherDetailPage.vue')
  },
  {
    path: 'my-voucher/:uuid',
    name: 'my-voucher.detail',
    meta: {
      title: 'My Voucher Detail',
      subject: 'my-voucher'
    },
    component: () => import('./detail/MyVoucherDetailPage.vue')
  }
]
