export default [
  {
    path: 'explore',
    name: 'brand',
    meta: {
      title: 'Explore',
      subject: 'brand'
    },
    component: () => import('./list/BrandListPage.vue')
  },
  {
    path: 'explore/:uuid',
    name: 'brand.detail',
    meta: {
      title: 'Explore',
      subject: 'brand'
    },
    component: () => import('./detail/BrandDetailPage.vue')
  }
]
