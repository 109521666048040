export default () => {
  const selectedProject: '707' | 'Atmos' = import.meta.env.VITE_PROJECT
  const contents = {
    '707': {
      color: '#C49A6C'
    },
    Atmos: {
      color: '#2A327F'
    }
  }

  function imageUrl(url: string) {
    if (url) {
      const env = import.meta.env.VITE_APP_IMAGE_URL
      const result = env + url
      return result
    }

    return ''
  }

  const cleanPhoneNumber = (prefixToRemove: string, phoneNumber: string) => {
    if (prefixToRemove.startsWith('+')) {
      prefixToRemove = prefixToRemove.slice(1)
    }

    let temp = phoneNumber
    if (temp.startsWith(prefixToRemove)) {
      temp = temp.slice(prefixToRemove.length)
    }
    return temp
  }

  const getContent = (contents: any, value: string) => {
    const projectValue = selectedProject as '707' | 'Atmos'
    return contents[projectValue][value]
  }

  const baseColor = () => {
    return getContent(contents, 'color')
  }

  const titleCase = (value: string) => {
    return value ? value.charAt(0) + value.slice(1).toLowerCase() : ''
  }

  const ellipsisText = (text: string, limit: number = 150) => {
    if (text?.length > limit) {
      return text.substring(0, limit) + '...'
    }
    return text
  }

  const convertTextToHTMLLinks = (text: string) => {
    return text?.replace(
      /\[url="([^"]+)" text="([^"]+)"\]/g,
      (_all, url, text) => {
        return `<a href="${url}" target="_blank" style="color: ${baseColor()}">${text}</a>`
      }
    )
  }

  return {
    selectedProject,
    baseColor,
    imageUrl,
    cleanPhoneNumber,
    getContent,
    titleCase,
    ellipsisText,
    convertTextToHTMLLinks
  }
}
