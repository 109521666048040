import { createRouter, createWebHistory } from 'vue-router'

import Login from '@/pages/login/routes'
import Home from '@/pages/home/routes'
import Inbox from '@/pages/inbox/routes'
import Redemption from '@/pages/redemption/routes'
import MyVoucher from '@/pages/my-voucher/routes'
import Campaign from '@/pages/campaign/routes'
import News from '@/pages/news/routes'
import Mission from '@/pages/mission/routes'
import Merchant from '@/pages/merchant/routes'
import Profile from '@/pages/profile/routes'
import Transaction from '@/pages/transaction/routes'
import FAQ from '@/pages/faq/routes'
import Explore from '@/pages/explore/routes'
import Outlet from '@/pages/outlet/routes'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/app',
      component: () => import('@/components/layouts/MainLayout.vue'),
      children: [
        Home,
        Inbox,
        ...Redemption,
        ...MyVoucher,
        ...Campaign,
        ...News,
        ...Mission,
        ...Merchant,
        ...Profile,
        ...Transaction,
        ...FAQ,
        ...Explore,
        ...Outlet
      ]
    },
    {
      path: '/auth',
      component: () => import('@/components/layouts/AuthLayout.vue'),
      children: [Login]
    },
    {
      path: '/registration',
      component: () => import('@/pages/register/RegisterQRPage.vue'),
      meta: {
        title: 'QR Registration'
      }
    },
    {
      path: '/exchange',
      component: () => import('@/pages/exchange/ExchangePage.vue'),
      meta: {
        title: 'Exchange'
      }
    },
    { path: '/:pathMatch(.*)*', redirect: '/app/home' }
  ]
})

router.beforeEach((to, _from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  const title = to.meta.title
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    const selectedProject = import.meta.env.VITE_PROJECT
    const parentTitle =
      selectedProject === '707' ? ' | 707 Portal' : ' | Atmos Portal'

    document.title = (title + parentTitle) as any
  }
  // Continue resolving the route
  next()
})

export default router
