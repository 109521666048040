import '@fortawesome/fontawesome-pro/css/all.css'

import { library } from '@fortawesome/fontawesome-svg-core'

import { fad } from '@fortawesome/pro-duotone-svg-icons'

import { far } from '@fortawesome/pro-regular-svg-icons'

import { fal } from '@fortawesome/pro-light-svg-icons'

import { fas } from '@fortawesome/pro-solid-svg-icons'

library.add(fad, far, fal, fas)
