export default [
  {
    path: 'campaign',
    name: 'campaign',
    meta: {
      title: 'Campaign'
    },
    component: () => import('./list/CampaignListPage.vue')
  },
  {
    path: 'campaign/:uuid',
    name: 'campaign.detail',
    meta: {
      title: 'Campaign'
    },
    component: () => import('./detail/CampaignDetailPage.vue')
  }
]
