export default [
  {
    path: 'profile',
    name: 'profile',
    meta: {
      title: 'Profile'
    },
    component: () => import('./ProfilePage.vue')
  },
  {
    path: 'tier-benefit',
    name: 'tier-benefit',
    meta: {
      title: 'Tier Benefit'
    },
    component: () => import('./detail/BenefitPage.vue')
  },
  {
    path: 'membership-benefit',
    name: 'membership-benefit',
    meta: {
      title: 'Membership Benefit'
    },
    component: () => import('./detail/MembershipBenefitPage.vue')
  },
  {
    path: 'point-history',
    component: () => import('./detail/PointHistoryPage.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: 'point-history'
        }
      },
      {
        path: 'history',
        name: 'point-history',
        meta: {
          title: 'Point History'
        },
        component: () => import('./detail/point/PointHistory.vue')
      },
      {
        path: 'expiry',
        name: 'point-expiry',
        meta: {
          title: 'Point History'
        },
        component: () => import('./detail/point/PointExpiry.vue')
      }
    ]
  },
  {
    path: 'terms-conditions',
    name: 'terms-conditions',
    meta: {
      title: 'Terms & Conditions'
    },
    component: () => import('./detail/TnCPage.vue')
  },
  {
    path: 'privacy-policy',
    name: 'privacy-policy',
    meta: {
      title: 'Privacy Policy'
    },
    component: () => import('./detail/PrivacyPolicyPage.vue')
  }
]
